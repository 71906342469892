<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="ID">
        <el-input style="width: 200px" clearable v-model="searchData.id" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input style="width: 200px" clearable v-model="searchData.realname" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input style="width: 200px" clearable v-model="searchData.mobile" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="会员等级">
        <el-input style="width: 200px" clearable v-model="searchData.level" size="mini" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- list -->
    <el-table :data="list">
      <!-- 展开 -->
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left">
            <el-row :gutter="10">
              <el-col :span="12" v-if="props.row.inviterUser">
                <el-form-item label="邀请人信息:">
                  <div class="inviter">
                    <el-image
                      style="width: 60px; height: 60px; border-radius: 5px; margin-right: 10px"
                      :src="props.row.inviterUser.face"
                      :preview-src-list="[props.row.inviterUser.face]"
                      fit="contain"
                    >
                      <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
                    </el-image>
                    <div class="inviter-info">
                      <div class="inviter-name">{{ props.row.inviterUser.realname }}</div>
                      <div class="inviter-mobile">{{ props.row.inviterUser.mobile }}</div>
                      <div class="inviter-lv">{{ props.row.inviterUser.levelDsc }}</div>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="props.row.userBalance">
                <el-form-item label="钱包信息：">
                  <div class="balance-box">
                    <div class="balance-item">
                      <span>萝卜币</span>
                      <span>{{ props.row.userBalance.totalCoin }}</span>
                    </div>
                    <div class="balance-item">
                      <span>可提现</span>
                      <span>{{ props.row.userBalance.availableCoin }}</span>
                    </div>
                    <div class="balance-item">
                      <span>冻结</span>
                      <span>{{ props.row.userBalance.freezeCoin }}</span>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="id" min-width="100" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="nickname" label="昵称" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="sex" label="性别" min-width="100">
        <template slot-scope="{ row }">
          <el-tag v-if="row.sex == 1" type="primary" size="mini">男</el-tag>
          <el-tag v-if="row.sex == 2" type="danger" size="mini">女</el-tag>
          <el-tag v-if="row.sex == 3 || !row.sex" type="info" size="mini">未知</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号码" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="face" label="头像" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-image style="width: 60px; height: 60px; border-radius: 5px; display: block" :src="row.face" fit="cover" :preview-src-list="[row.face]">
            <div style="line-height: 60px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="levelDsc" label="等级" min-width="100" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag type="primary" size="mini">{{ row.levelDsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="注册时间" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" width="170" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleJubao(row)"> 查看举报 </el-link>
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
          <el-link type="primary" :underline="false" @click="handleCharge(row)">充值</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情 -->
    <Detail ref="detail"></Detail>
    <JubaoList ref="jubao" :uid="uid"></JubaoList>
    <!-- 充值 -->
    <el-dialog title="充值" :visible.sync="show_charge" width="width">
      <el-descriptions v-if="detail.userBalance" direction="vertical" :column="5" size="small" border>
        <el-descriptions-item label="id">{{ detail.id }} </el-descriptions-item>
        <el-descriptions-item label="昵称">{{ detail.nickname }} </el-descriptions-item>
        <el-descriptions-item label="萝卜币">{{ detail.userBalance.totalCoin }} </el-descriptions-item>
        <el-descriptions-item label="可提现">{{ detail.userBalance.availableCoin }} </el-descriptions-item>
        <el-descriptions-item label="冻结">{{ detail.userBalance.freezeCoin }} </el-descriptions-item>
      </el-descriptions>
      <el-form style="margin-top: 10px" ref="formCharge" :model="chargeData" label-width="">
        <el-form-item label="充值金额：">
          <el-input-number size="mini" v-model="chargeData.money" :min="0" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_charge = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmCharge" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI, chargeAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import JubaoList from './jubao-list.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail, JubaoList },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        realname: '',
        mobile: '',
        level: '',
        id: ''
      },
      total: 0,
      detail: {},
      uid: '',
      chargeData: {
        id: '',
        money: ''
      },
      show_charge: false,
      
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    async getList() {
      let params = { ...this.searchData }
      if (this.$route.query.id) {
        params.id = this.$route.query.id
      }
      const res = await getListAPI(params)
      this.list = res.records
      this.total = res.totalRow
      
    },
 
    // 详情
    async handleDetail({ id }) {
      // this.detail = await getDetailAPI(id)
      this.$refs.detail.id = id
      this.$refs.detail.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      if (this.$route.query.id) {
        this.$router.push({ name: 'CustomerList' })
        // return
      }
      this.getList()
    },
    handleJubao({ id }) {
      this.uid = id
      this.$refs.jubao.show_jubao = true
    },
    handleCharge(row) {
      this.detail = row
      this.chargeData = {
        id: row.id,
        money: ''
      }
      this.show_charge = true
    },
    confirmCharge() {
      chargeAPI(this.chargeData).then(() => {
        this.getList()
        this.$message.success('充值成功')
        this.show_charge = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .inviter {
    display: inline-flex;
    align-items: center;
    background: #f8f8f9;
    border-radius: 5px;
    padding: 0 20px;
    .inviter-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .inviter-name {
        font-weight: 600;
      }
    }
  }
  .balance-box {
    display: flex;
    // align-items: center;
    .balance-item {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      background: #f5f5f5;
      border-radius: 5px;
      padding: 0 20px;
      margin-right: 20px;
      span {
        &:last-child {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
